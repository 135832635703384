<template>
  <a-config-provider :locale="locale" 
    :theme="{
    token: {
      colorPrimaryBg: '#407ec9',
      colorPrimary: '407EC9',
      colorInfo: '#407ec9',
      borderRadius: 15,
      fontFamily: 'Lexend Deca',
      colorBgLayout: '#ffffff'
    }
  }">
    <a-layout>
      <router-view/>
    </a-layout>
  </a-config-provider>
</template>

<script setup>
import esES from "ant-design-vue/es/locale/es_ES"

const locale = esES
</script>

<style scoped>
.title {
  text-align: center;
  font-weight: bold;
  margin: 2rem 0;
  text-transform: uppercase;
}
</style>