import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('@/views/index.vue'),
  },{
    path: '/distrito',
    name: 'Distrito',
    component: ()=>import('@/views/distrito.vue')
  },{
    path: '/gcont',
    name: 'Gcont',
    beforeEnter() {  // Redirige a la URL externa
      window.location.href = 'https://transparencia.santatecla.gob.sv/gcont/index.php?app=gcont'
    }
  },{
    path: '/:pathMatch(.*)*', // Esta es la ruta comodín
    name: 'NotFound',
    component: ()=>import('@/views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" })
  }
})

export default router
